<template>
  <div class="col-md-3">
    <div class="card mini-stats-wid">
      <div class="card-body">
        <div class="media" :style="$i18n.locale == 'ar' ? '' : 'direction:rtl'">
          <div class="media-body">
            <p
              class="text-muted fw-medium"
              :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-18' : ''"
            >
              {{ title }}
            </p>
            <h4 class="mb-0">
              <span
                v-if="loading"
                class="spinner-grow spinner-grow-sm mr-1"
                role="status"
                aria-hidden="true"
              >
              </span>
              <span v-if="!loading">
                {{ totaly }}
              </span>
            </h4>
          </div>

          <div
            class="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center"
          >
            <span
              class="avatar-title"
              :class="theme_color == 'purple' ? 'btn-purple' : ''"
            >
              <i :class="icon + ' font-size-24'"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TotalCard",
  props: [
    "table",
    "title",
    "icon",
    //'totaly'
  ],
  data() {
    return {
      //
      auth: {
        access_token: "",
      },
      loading: true,
      days: 7,
      totaly: 0,
      percentage: "",
      arrow: "",

      refs: "reports",
      theme_color: "",
    };
  },
  mounted() {},
  watch: {
    //
  },
  created() {
    //
    if (localStorage.getItem("access_token")) {
      this.auth.access_token = localStorage.getItem("access_token");
    }
    if (localStorage.getItem("theme_color")) {
      this.theme_color = localStorage.getItem("theme_color");
    }

    this.fetchTotal();
  },
  methods: {
    //
    fetchTotal() {
      this.loading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/" + this.refs + "/totalCard/" + this.table,
        method: "GET",
        data: {},
        params: {},
      };
      this.axios(options)
        .then((res) => {
          this.loading = false;
          this.totaly = res.data.total;
          this.percentage = res.data.percentage;
          this.arrow = res.data.arrow;
        })
        .catch(() => {})
        .finally(() => {});
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
